import React from "react";
import styled from "styled-components";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { color } from "styled-system";
import { FormikTouched, FormikErrors } from "formik";
import Input from "./index";
import { ButtonProps } from "../Button";

const Wrapper = styled(Div)`
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
`;

const StyledInput = styled(Input)`
  padding: 10px 12px;
  border-width: 2px;
  border-radius: 4px 0 0 4px;
  flex: 1;
  width: 100%;
`;

const Button = styled.button`
  border: 2px solid;
  border-radius: 0 4px 4px 0;
  border-color: ${({ theme }) => theme.colors.monochrome[1]};
  padding-left: ${({ theme }) => theme.space[2]};
  padding-right: ${({ theme }) => theme.space[2]};
  font-weight: 700;
  font-size: 11px;
  line-height: 15.4px;
  text-align: center;
  letter-spacing: 0.16em;

  :disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.monochrome[1]};
    color: ${({ theme }) => theme.colors.monochrome[3]};
  }

  ${color}
`;

interface Props extends ButtonProps {
  buttonProps: any;
  buttonChildren: any;
  placeholder?: string;
  name: string;
  id: string;
  disabled?: boolean;
  touched?: FormikTouched<any>;
  errors?: FormikErrors<any>;
}

export const InputWithButton = (props: Props) => {
  const {
    buttonProps,
    buttonChildren,
    placeholder,
    name,
    id,
    disabled,
    touched,
    errors,
    ...rest
  } = props;

  return (
    <Wrapper {...rest}>
      <StyledInput wrapperProps={{ flex: 1 }} {...props} />
      <Flex>
        <Button
          type="submit"
          disabled={disabled}
          placeholder={placeholder}
          name={name}
          id={id}
          touched={touched}
          errors={errors}
          {...buttonProps}
        >
          {buttonChildren}
        </Button>
      </Flex>
    </Wrapper>
  );
};
