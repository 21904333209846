import React from "react";
import { useFamilyFriendlyFlag } from "hooks/useFeatureFlags";
import { Check } from "app/components/Icon";
import { useUserHasTrialed } from "hooks/Subscriptions/useUserHasTrialed";
import styles from "./styles.module.scss";

const FAMILY_BENEFIT = (
  <>
    <strong>FAMILY: Dance Alongs</strong> for the whole family. Includes
    parental controls.
  </>
);

const STUDIO_BENEFIT = (
  <>
    <strong>STUDIO: 1,000+ classes</strong> for all skill levels
  </>
);

interface Props {
  canTrial: boolean;
}

export function BenefitsText({ canTrial }: Props) {
  const isFamilyFeatureEnabled = useFamilyFriendlyFlag();
  const { hasTrialed } = useUserHasTrialed();

  const TRIAL_BENEFITS = [
    <>
      You&apos;ll be reminded <strong>2 days</strong> before your trial ends,
      easy to cancel
    </>,
    STUDIO_BENEFIT,
    FAMILY_BENEFIT,
  ];

  const NON_TRIAL_BENEFITS = [
    STUDIO_BENEFIT,
    FAMILY_BENEFIT,
    <>
      <strong>Guided Programs</strong> for Heels, Hip Hop, Ballet & more
    </>,
    <>
      <strong>Easy</strong> Cancelation
    </>,
  ];

  if (!isFamilyFeatureEnabled) {
    TRIAL_BENEFITS.pop();
    NON_TRIAL_BENEFITS.splice(1, 1);
  }

  const benefits =
    canTrial && !hasTrialed ? TRIAL_BENEFITS : NON_TRIAL_BENEFITS;

  return (
    <div className={styles.contentContainerOuter}>
      <div className={styles.benefitsContainer}>
        {benefits.map((benefit, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} className={styles.textContainer}>
            <Check className={styles.icon} />
            <p>{benefit}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
