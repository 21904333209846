// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles-module__amountDueTotal--UnHkg{color:#0b79fb;font-weight:600}.styles-module__breakdownText--MlBgH{font-size:14px;margin:16px 0}@media(max-width: 1169px){.styles-module__breakdownText--MlBgH{font-size:12px;margin:8px 0}}", "",{"version":3,"sources":["webpack://./src/app/components/Checkout/CheckoutHeaderTotals/styles.module.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA,sCACE,aCQK,CDPL,eAAA,CAGF,qCACE,cAAA,CACA,aAAA,CAEA,0BAJF,qCAKI,cAAA,CACA,YAAA,CAAA","sourcesContent":["@import \"styles/_variables\";\n\n.amountDueTotal {\n  color: $blue;\n  font-weight: 600;\n}\n\n.breakdownText {\n  font-size: 14px;\n  margin: 16px 0;\n\n  @media (max-width: $lgBreakpoint-1) {\n    font-size: 12px;\n    margin: 8px 0;\n  }\n}\n","/* Breakpoints */\n$xsBreakpoint: 374px;\n$smBreakpoint: 480px;\n$mdBreakpoint: 768px;\n$mdBreakpoint-1: 767px;\n$lgBreakpoint: 1170px;\n$lgBreakpoint-1: 1169px;\n$xlBreakpoint: 1440px;\n\n/* Colors */\n$black: #000000; // rgb(0, 0, 0);\n$blue: #0b79fb; // rgb(11, 121, 251);\n$white: #ffffff; // rgb(255, 255, 255);\n$offWhite: #f8f6f2;\n$primary: #0b79fb; // rgb(11, 121, 251);\n$lightGrey: #ebefef; // rgb(235, 239, 239);\n$grey: #bcbaba; // rgb(188, 186, 186);\n$green: #00a779;\n$red: #c10000;\n$gold: #ffb800;\n\n$error: #df6060; // rgb(223, 96, 96);\n\n$cleanMint0: #c8f9db; // rgb(200, 249, 219);\n$cleanMint5: #6fcf96; // rgb(111 207 150);\n\n$monochrome1: #ebefef; // rgb(235, 239, 239); $lightGrey;\n$monochrome2: #c8cbcb; // rgb(200, 203, 203);\n$monochrome3: #a5a8a8; // rgb(165, 168, 168)\n$monochrome4: #848787; // rgb(132, 135, 135);\n$monochrome5: #656666; // rgb(101, 102, 102);\n$monochrome6: #474848; // rgb(71, 72, 72);\n$monochrome8: #111111; // rgb(17, 17, 17);\n\n$redMercedes3: #d84141; // rgb(216, 65, 65);\n\n/* Misc */\n$navbarHeight: 60px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"amountDueTotal": "styles-module__amountDueTotal--UnHkg",
	"breakdownText": "styles-module__breakdownText--MlBgH"
};
export default ___CSS_LOADER_EXPORT___;
