import React from "react";
import useCheckoutEstimate from "hooks/Subscriptions/useCheckoutEstimate";
import { useUserHasTrialed } from "hooks/Subscriptions/useUserHasTrialed";
import { BillingPeriodUnit } from "services/graphql";
import { useCheckout } from "modules/selectors";
import { P1 } from "app/components/Typography";
import styles from "./styles.module.scss";

export function CheckoutHeaderTotals() {
  const { selectedPlan } = useCheckout();
  const { checkoutEstimate } = useCheckoutEstimate();
  const { hasTrialed } = useUserHasTrialed();
  const { couponEstimate } = checkoutEstimate || {};
  const { canTrial, periodUnit, priceInCents } = selectedPlan;
  let breakdownText;
  let amountDueToday;

  const planPrice = (
    (couponEstimate?.discountPlanPriceInCents ?? priceInCents) / 100
  ).toFixed(2);

  if (canTrial && !hasTrialed) {
    amountDueToday = "0.00";

    breakdownText = (
      <>
        After your free trial, you&apos;ll be automatically enrolled in our{" "}
        <strong>
          ${planPrice} + tax/{periodUnit} plan.{" "}
        </strong>
        Change or cancel anytime during your trial to not be charged.
      </>
    );
  } else if (periodUnit === BillingPeriodUnit.Year) {
    amountDueToday = planPrice;
    breakdownText = (
      <>
        You&apos;ll be automatically enrolled in our{" "}
        <strong>
          ${planPrice} + tax/{periodUnit} plan.{" "}
        </strong>
        Change or cancel anytime.
      </>
    );
  } else {
    amountDueToday = planPrice;
    breakdownText = null;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <P1>Amount due today:</P1>
        <P1 className={styles.amountDueTotal}>${amountDueToday}</P1>
      </div>

      <p className={styles.breakdownText}>{breakdownText}</p>
    </>
  );
}
