import React from "react";
import { PlusCircle } from "app/components/Icon";
import { useCheckout } from "modules/selectors";
import { EnterCoupon } from "app/components/Checkout/EnterCoupon";
import { PeriodUnitAdverbs } from "constants/cart";
import { CheckoutHeaderTotals } from "app/components/Checkout/CheckoutHeaderTotals";
import styles from "./styles.module.scss";
import { BenefitsText } from "../BenefitsText";

interface Props {
  headerText: string;
  setIsSelectingPlan(isSelectingPlan: boolean): void;
}

export function CheckoutHeader({ headerText, setIsSelectingPlan }: Props) {
  const { selectedPlan } = useCheckout();

  return (
    <div>
      <div className={styles.selectedPlanHeader}>
        <header>{selectedPlan.accessType} </header>
        {
          PeriodUnitAdverbs[
            selectedPlan.periodUnit as keyof typeof PeriodUnitAdverbs
          ]
        }
      </div>
      <div className={styles.headerContainer}>
        <h2 className={styles.headerText}>{headerText}</h2>
        <BenefitsText canTrial={selectedPlan.canTrial} />
        <div>
          <div className={styles.toggleText}>
            <div onClick={() => setIsSelectingPlan(true)}>
              <PlusCircle />
              <p>Switch Plans</p>
            </div>
          </div>
          <EnterCoupon />
        </div>
        <div className={styles.dividerThin} />
        <CheckoutHeaderTotals />
      </div>
    </div>
  );
}
