import React, { useState, useMemo } from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { setCoupon } from "modules/checkout";
import useCheckoutEstimate from "hooks/Subscriptions/useCheckoutEstimate";
import Div from "app/components/Div";
import Text from "app/components/Text";
import Flex from "app/components/Flex";
import { P2 } from "app/components/Typography";
import SpanLink from "app/components/SpanLink";
import { InputWithButton } from "app/components/Input/InputWithButton";
import Icon, { PlusCircle, MinusCircle } from "app/components/Icon";
import Loader from "app/components/Loader";
import { useCheckout } from "modules/selectors";
import { AbbreviatedPeriodUnits, PeriodUnitAdverbs } from "constants/cart";
import styles from "./styles.module.scss";

export function EnterCoupon() {
  const dispatch = useDispatch();
  const [showCoupon, toggleShowCoupon] = useState(false);
  const { selectedPlan, coupon } = useCheckout();
  const {
    checkoutEstimate,
    loading: loadingCheckoutEstimate,
    error: checkoutEstimateError,
  } = useCheckoutEstimate();

  const loading = loadingCheckoutEstimate;

  const couponSuccess = useMemo(() => {
    return coupon && checkoutEstimate?.coupon;
  }, [checkoutEstimate, coupon]);

  const couponError = useMemo(() => {
    return checkoutEstimateError?.graphQLErrors?.find(
      err => err.extensions.code === "COUPON_NOT_FOUND"
    );
  }, [checkoutEstimateError]);

  const referralCouponApplied = useMemo(() => {
    return couponSuccess && coupon?.toUpperCase() === "REFERRAL";
  }, [couponSuccess, coupon]);

  const discountDuration = checkoutEstimate?.coupon?.discountDuration;
  const periodUnit = selectedPlan?.periodUnit;
  const abbreviatedPeriodUnit =
    AbbreviatedPeriodUnits[
      selectedPlan?.periodUnit as keyof typeof AbbreviatedPeriodUnits
    ];
  const periodUnitAdverb =
    PeriodUnitAdverbs[
      selectedPlan?.periodUnit as keyof typeof AbbreviatedPeriodUnits
    ];
  const discountAmountOrPercentage = checkoutEstimate?.coupon?.discountAmount
    ? `$${checkoutEstimate?.coupon?.discountAmount / 100}`
    : `${checkoutEstimate?.coupon?.discountPercentage}%`;

  return (
    <Div maxWidth={{ _: "100%", lg: "450px" }}>
      <>
        <Div>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex
              alignItems="center"
              color="monochrome.4"
              cursor="pointer"
              onClick={() => toggleShowCoupon(!showCoupon)}
              className={styles.toggleText}
            >
              <Icon as={showCoupon ? MinusCircle : PlusCircle} />
              <P2
                textDecoration="underline"
                fontSize={{ _: "12px", lg: "18px" }}
              >
                Enter Coupon
              </P2>
            </Flex>
            {couponSuccess && (
              <SpanLink
                color="monochrome.6"
                fontSize="12px"
                underline
                onClick={() => {
                  dispatch(setCoupon(null));
                }}
              >
                Remove
              </SpanLink>
            )}
          </Flex>
          {showCoupon && !couponSuccess && (
            <Formik
              initialValues={{ coupon }}
              onSubmit={values => {
                dispatch(setCoupon(values.coupon));
              }}
              render={({ touched, errors, values }) => (
                <Form>
                  <Flex>
                    <InputWithButton
                      placeholder="Enter a coupon/promo"
                      name="coupon"
                      id="coupon"
                      disabled={loading}
                      touched={touched}
                      errors={errors}
                      buttonProps={{
                        disabled: loading || !values.coupon,
                        bg: "blue",
                        color: "white",
                      }}
                      buttonChildren={
                        loading ? <Loader width={24} light /> : "APPLY"
                      }
                    />
                  </Flex>
                </Form>
              )}
            />
          )}
          {showCoupon && couponSuccess && (
            <Div borderRadius="4px" p="12px" bg="cleanMint.0">
              {referralCouponApplied ? (
                <Text fontSize="14px">
                  <strong>$10 referral credit</strong> successfully applied!
                </Text>
              ) : (
                <Text fontSize="14px">
                  Coupon <strong>{coupon.toUpperCase()}</strong> successfully
                  applied!
                </Text>
              )}
            </Div>
          )}
          {showCoupon && couponSuccess && (
            <P2 mt={3}>
              {periodUnitAdverb} subscription: {discountAmountOrPercentage}/
              {abbreviatedPeriodUnit} to first{" "}
              {discountDuration > 1 && discountDuration} {periodUnit}
              {discountDuration > 1 && "s"}
            </P2>
          )}
          {showCoupon && couponError && (
            <P2 mt={3} color="redMercedes.3">
              {couponError.message}
            </P2>
          )}
        </Div>
      </>
    </Div>
  );
}
